module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/zeit/6ed2cd93/src/assets/intl","languages":["en"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Christian Zwergius Web","short_name":"Zwergius","start_url":"/","background_color":"rgb(36, 36, 36, .95)","theme_color":"rgba(51, 51, 51, .8)","display":"standalone","icon":"src/images/favicon.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
