/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "typeface-ibm-plex-mono"
import "typeface-ibm-plex-sans"
import smoothscroll from "smoothscroll-polyfill"

smoothscroll.polyfill()
